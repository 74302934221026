<template>
  <div class="channel">
    <el-row type="flex" justify="space-between">
      <el-col :span="8" v-for="(logo, index) in logos" :key="index">
        <el-card shadow="hover" style="margin: 5px">
          <el-image height="100" :src="logo.src" />
          <el-button type="primary" plain @click="routeTo(logo.value)">{{
            logo.text
          }}</el-button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { PROCESS_ACTIVE } from "../../assets/constant.js";
export default {
  created() {
    this.$store.dispatch("setProcessActive", PROCESS_ACTIVE.CHANNEL);
  },
  data() {
    return {
      logos: [
        {
          text: "华为云",
          value: "huawei",
          src: require("../../assets/cloudLogo/huawei.png"),
        },
        {
          text: "阿里云",
          value: "ali",
          src: require("../../assets/cloudLogo/ali.png"),
        },
        {
          text: "金山云",
          value: "jinshan",
          src: require("../../assets/cloudLogo/jinshan.png"),
        },
      ],
    };
  },
  methods: {
    routeTo(formKey) {
      this.$store.commit("resetReqData");
      this.$store.commit("setAccessData", { cloud: formKey });
      this.$store.commit("setCloudBrand", formKey);
      this.$store.dispatch("setClusterPrimaryKeyIntoReq");
      this.$router.push("/form/" + formKey + "Access");
    },
  },
};
</script>

<style>
.van-button {
  width: 100px;
  position: absolute;
  top: 100px;
  left: 0px;
  font-size: 10px;
}

.ds-wrapper {
  /* background-color: red; */
  display: block;
  position: relative;
  height: 145px;
}

.van-button__text {
  font-size: 0.1rem;
}
</style>